import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import "./header.scss"

const links = [
  {
    title: "Home",
    uri: "/",
  },
  {
    title: "About Me",
    uri: "/about",
  },
  {
    title: "Skills",
    uri: "/skills",
  },
  {
    title: "Blog",
    uri: "/blog",
  },
]

const NavLinks = () => (
  <nav className="d-flex flex-column text-center">
    {links.map((link, idx) => (
      <div className="py-2" key={idx}>
        <Link to={link.uri}>{link.title}</Link>
      </div>
    ))}
    <div className="py-2">
      <a href="https://github.com/THISS" target="__blank">
        GitHub
      </a>
    </div>
    <div className="py-2">
      <a
        href="https://www.linkedin.com/in/brenton-holswich/"
        target="__blank"
      >
        linkedIn
      </a>
    </div>
  </nav>
)

const Header = ({ siteTitle, subTitle }) => {
  const [toggled, setToggled] = useState(false)
  return (
    <header className="container-fluid header-container">
      <section className="row">
        <div className="col-12 pb-2">
          <h1>
            <Link to="/">{siteTitle}</Link>
          </h1>
          <h5 className="snow">{subTitle}</h5>
        </div>
        <div className="col-12 d-flex d-sm-none justify-content-end">
          <button
            className={`icon-bar-container ${toggled && "toggled"}`}
            onClick={() => setToggled(!toggled)}
          >
            <span className="icon-bar bar1"></span>
            <span className="icon-bar bar2"></span>
            <span className="icon-bar bar3"></span>
          </button>
        </div>
        <div className="col-12 d-sm-none d-flex justify-content-center">
          {toggled && <NavLinks></NavLinks>}
        </div>
        <div className="col-12 d-none d-sm-flex justify-content-center">
          <NavLinks></NavLinks>          
        </div>
      </section>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  subTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  subTitle: ``,
}

export default Header
