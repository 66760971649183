/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.scss"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            subtitle
          }
        }
      }
    `}
    render={data => (
      <div className="container-fluid site-container d-flex">
        <div className="row flex-fill">
          <div className="d-none d-sm-flex col-sm-4 col-lg-3 col-xl-2 position-fixed h-100">
            <div className="row">
              <Header siteTitle={data.site.siteMetadata.title} subTitle={data.site.siteMetadata.subtitle} />
            </div>
          </div>
          <div className="d-sm-none d-flex col-12 flex-fill">
            <div className="row flex-fill">
              <Header siteTitle={data.site.siteMetadata.title} subTitle={data.site.siteMetadata.subtitle} />
            </div>
          </div>
          <div className="offset-sm-4 offset-lg-3 offset-xl-2 d-flex flex-fill">
            <div className="d-flex flex-fill flex-column justify-content-between">
              <main className="flex-fill d-flex">{children}</main>
              <footer>
                © {new Date().getFullYear()}, Built with
                {` `}
                <a href="https://www.gatsbyjs.org">Gatsby</a>
              </footer>
            </div>
          </div>
        </div>

      </div>      
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
